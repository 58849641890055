import { AppMode } from 'routes'

import { User } from 'graphql/types'

export enum Role {
  // App
  SuperAdmin = 'superadmin',
  Member = 'member',
  Admin = 'admin',
  Consultant = 'consultant',
}

type UserPermissions = Pick<
  User,
  | 'v1RoleId'
  | 'wsParticipant'
  | 'workshopFeedbackReportsPermission'
  | 'accessNpt' // accessNpt: enables NPT when isNpt or wsParticipant is true
  | 'isLms'
  | 'isLv1'
  | 'isIvl'
  | 'isVerified' // we use isVerified for global permissions
  | 'lv1SubgroupAdmin'
>

export type UserRole = (Pick<User, 'role'> & Partial<UserPermissions>) | undefined

// because of BE types we need null | undefined
type PermissionValue = number | boolean | null | undefined | (number | boolean | null | undefined)[]

// All conditions within a single PermissionSet must be met (represented by the AND relation).
type PermissionSet = Partial<Record<keyof UserPermissions, PermissionValue>>

export type Abilities = {
  roles: readonly Role[]
  permissions: PermissionSet[]
}

const rolesWithPermissions = (roles: Role[], permissions: PermissionSet[]) => {
  return { roles, permissions }
}

/**
 * Each PermissionSet object contains conditions that all must be true (an AND relationship).
 * Each different PermissionSet object represents an alternative set of conditions (an OR relationship).
 */
export const abilities = {
  global: {
    manageProfile: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant, Role.Member],
      [{ isVerified: true }],
    ),
    manageOrganizations: rolesWithPermissions([Role.SuperAdmin], [{ isVerified: true }]),
    manageOrgMembers: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant],
      [{ isVerified: true }],
    ),
    coachingLogs: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant],
      [{ isVerified: true }],
    ),
    organizationStatistics: rolesWithPermissions([Role.SuperAdmin], [{ isVerified: true }]),
    viewWorkshopsCalendar: rolesWithPermissions(
      [Role.SuperAdmin, Role.Consultant],
      [{ isVerified: true }],
    ),
  },
  [AppMode.NPT]: {
    manageVariables: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant],
      [{ accessNpt: true }],
    ),
    accessAdminDashboard: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin],
      [{ accessNpt: true }],
    ),
    createNegotiations: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant, Role.Member],
      [{ accessNpt: true }],
    ),
  },
  [AppMode.Lv1]: {
    playGame: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [{ isLv1: true }],
    ),
    manageSubgroups: rolesWithPermissions([Role.SuperAdmin], [{ isLv1: true }]),
    accessLv1AdminSubgroups: rolesWithPermissions([Role.Admin, Role.Consultant], [{ isLv1: true }]),
    accessSubgroupAdminSubgroups: rolesWithPermissions(
      [Role.Member],
      [{ isLv1: true, lv1SubgroupAdmin: true }], // AND
    ),
    accessAdminDashboard: rolesWithPermissions(
      [Role.SuperAdmin, Role.Admin, Role.Consultant],
      [{ isLv1: true }],
    ),
    accessLv1: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [{ isLv1: true }],
    ),
  },
  [AppMode.Learning]: {
    accessLearning: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [{ isLms: true }],
    ),
    manageLearning: rolesWithPermissions([Role.SuperAdmin], [{ isLms: true }]),
  },
  [AppMode.Workshop]: {
    accessV1Admin: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [
        {
          v1RoleId: [1, 2],
        },
        // OR
        {
          workshopFeedbackReportsPermission: true,
        },
      ],
    ),
    manageWorkshops: rolesWithPermissions([Role.SuperAdmin], [{ isVerified: true }]),
    accessWorkshop: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [{ wsParticipant: true }],
    ),
  },
  ivl: {
    accessIVL: rolesWithPermissions(
      [Role.SuperAdmin, Role.Member, Role.Admin, Role.Consultant],
      [{ isIvl: true }],
    ),
    manageIVL: rolesWithPermissions([Role.SuperAdmin], [{ isIvl: true }]),
    accessAdminDashboard: rolesWithPermissions([Role.SuperAdmin, Role.Admin], [{ isIvl: true }]),
  },
}

export const roleLabel = (role: string) => {
  switch (role) {
    case Role.SuperAdmin:
      return 'Super Admin'
    // NPT
    case Role.Member:
      return 'Member'
    case Role.Admin:
      return 'Admin'
    case Role.Consultant:
      return 'Consultant'
    default:
      return role
  }
}

const userHasRoles = (user: UserRole, ...expected: readonly Role[]): boolean => {
  return user ? expected.includes(user.role as Role) : false
}

// Checks if a user has the permissions defined in a PermissionSet.
// It returns true if the user meets all the conditions of any PermissionSet in the input array.
const userHasPermissions = (user: UserRole, permissions: PermissionSet[]): boolean => {
  if (!user) return false

  // Check if the user meets all the conditions of any PremissionSet.
  return permissions.some((expected) => {
    const keys = Object.keys(expected) as (keyof UserPermissions)[]

    // Check if the user meets all the conditions within a PremissionSet.
    return keys.every((permissionKey) => {
      // If the condition is an array, check if the user's attribute is in the array.
      const permissions = expected[permissionKey]
      if (permissions && Array.isArray(permissions)) {
        return expected[permissionKey] ? permissions.includes(user[permissionKey]) : false
      } else {
        // Otherwise, check if the user's attribute matches the condition.
        return expected[permissionKey] === user[permissionKey]
      }
    })
  })
}

// Check if a user has the specified role AND if they meet any of the PermissionSets.
export const userCan = (user: UserRole, abilities: Abilities): boolean =>
  userHasRoles(user, ...abilities.roles) && userHasPermissions(user, abilities.permissions)

// Global
export const userIsSuperAdmin = (user: UserRole) => userHasRoles(user, Role.SuperAdmin)
export const userIsConsultant = (user: UserRole) =>
  userHasRoles(user, Role.Consultant, Role.SuperAdmin)
export const userIsConsultantOnly = (user: UserRole) => userHasRoles(user, Role.Consultant)
export const userIsAdminOrConsultant = (user: UserRole) =>
  userHasRoles(user, Role.Admin, Role.Consultant, Role.SuperAdmin)
export const userIsAdmin = (user: UserRole) => userHasRoles(user, Role.Admin, Role.SuperAdmin)
export const userIsAdminOnly = (user: UserRole) => userHasRoles(user, Role.Admin)
export const userIsMember = (user: UserRole) => userHasRoles(user, Role.Member)
export const userWsParticipantRole = (user: UserRole) =>
  userHasPermissions(user, [{ wsParticipant: true }])
export const userIsNptRole = (user: UserRole) => userHasPermissions(user, [{ accessNpt: true }])
export const userIsLmsRole = (user: UserRole) => userHasPermissions(user, [{ isLms: true }])
export const userIsLv1Role = (user: UserRole) => userHasPermissions(user, [{ isLv1: true }])
export const userIsIvlRole = (user: UserRole) => userHasPermissions(user, [{ isIvl: true }])

// SSO
export const userIsSso = (user: Pick<User, 'isSso'> | undefined) => user?.isSso ?? false
