import * as Types from '../../types';

import { gql } from '@apollo/client';
import { IvlVideoFieldsFragmentDoc } from '../IvlVideo/IvlVideoFields.gen';
import { IvlQuestionFieldsFragmentDoc } from '../IvlQuestion/IvlQuestionFields.gen';
export type CourseItemFieldsFragment = { __typename?: 'CourseItem', id: string, courseId: string, title: string | null, rank: number, courseItemType: Types.CourseItemType, videoId: string | null, questionId: string | null, percentCompleted: number | null, durationWatched: number | null, video: { __typename?: 'IvlVideo', id: string, title: string | null, description: string | null, duration: number | null, thumbnailUrl: string | null, languageCode: string | null, vimeoId: string | null, processingStatus: Types.VideoProcessingStatus, createdAt: string } | null, question: { __typename?: 'IvlQuestion', id: string, question: string | null, languageCode: string | null, repeat: boolean | null, correctOption: string | null, isDeleted: boolean, createdAt: string, ivlQuestionOptionsByQuestionId: { __typename?: 'IvlQuestionOptionsConnection', nodes: Array<{ __typename?: 'IvlQuestionOption', id: string, option: string | null, optionType: Types.IvlQuestionOptionType | null, questionId: string, explainerText: string | null, isOther: boolean | null, rank: number, createdAt: string, video: { __typename?: 'IvlVideo', id: string, title: string | null, thumbnailUrl: string | null } | null }> } } | null };

export const CourseItemFieldsFragmentDoc = gql`
    fragment CourseItemFields on CourseItem {
  id
  courseId
  title
  rank
  courseItemType
  videoId
  questionId
  percentCompleted
  durationWatched
  video {
    ...IvlVideoFields
  }
  question {
    ...IvlQuestionFields
  }
}
    ${IvlVideoFieldsFragmentDoc}
${IvlQuestionFieldsFragmentDoc}`;