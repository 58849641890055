import * as Types from '../../types';

import { gql } from '@apollo/client';
export type IvlQuestionOptionFieldsFragment = { __typename?: 'IvlQuestionOption', id: string, option: string | null, optionType: Types.IvlQuestionOptionType | null, questionId: string, explainerText: string | null, isOther: boolean | null, rank: number, createdAt: string, video: { __typename?: 'IvlVideo', id: string, title: string | null, thumbnailUrl: string | null } | null };

export const IvlQuestionOptionFieldsFragmentDoc = gql`
    fragment IvlQuestionOptionFields on IvlQuestionOption {
  id
  option
  optionType
  questionId
  video {
    id
    title
    thumbnailUrl
  }
  explainerText
  isOther
  rank
  createdAt
}
    `;