import * as Types from '../../types';

import { gql } from '@apollo/client';
import { IvlQuestionOptionFieldsFragmentDoc } from '../IvlQuestionOption/IvlQuestionOptionFields.gen';
export type IvlQuestionFieldsFragment = { __typename?: 'IvlQuestion', id: string, question: string | null, languageCode: string | null, repeat: boolean | null, correctOption: string | null, isDeleted: boolean, createdAt: string, ivlQuestionOptionsByQuestionId: { __typename?: 'IvlQuestionOptionsConnection', nodes: Array<{ __typename?: 'IvlQuestionOption', id: string, option: string | null, optionType: Types.IvlQuestionOptionType | null, questionId: string, explainerText: string | null, isOther: boolean | null, rank: number, createdAt: string, video: { __typename?: 'IvlVideo', id: string, title: string | null, thumbnailUrl: string | null } | null }> } };

export const IvlQuestionFieldsFragmentDoc = gql`
    fragment IvlQuestionFields on IvlQuestion {
  id
  question
  languageCode
  repeat
  correctOption
  isDeleted
  createdAt
  ivlQuestionOptionsByQuestionId(orderBy: RANK_ASC) {
    nodes {
      ...IvlQuestionOptionFields
    }
  }
}
    ${IvlQuestionOptionFieldsFragmentDoc}`;