import * as Types from '../../types';

import { gql } from '@apollo/client';
export type IvlVideoFieldsFragment = { __typename?: 'IvlVideo', id: string, title: string | null, description: string | null, duration: number | null, thumbnailUrl: string | null, languageCode: string | null, vimeoId: string | null, processingStatus: Types.VideoProcessingStatus, createdAt: string };

export const IvlVideoFieldsFragmentDoc = gql`
    fragment IvlVideoFields on IvlVideo {
  id
  title
  description
  duration
  thumbnailUrl
  languageCode
  vimeoId
  processingStatus
  createdAt
}
    `;